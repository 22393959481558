import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { Modal } from "./Modal";
import FormRowSelect from "./FormRowSelect";
import FormToggle from "./FormToggle";
import FormInput from "./FormInput";
import { useGlobalContext } from "../context/UserContext";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";

const UserDetails = ({
  close,
  userName,
  _id,
  balance,
  createdAt,
  phoneNumber,
  userType,
  lastLogin,
  email,
  handleUserTransaction,
  handleSendEmail,
  handleUpgradeUser,
  referredBy,
  specialPrices,
  apiToken,
  fullName,
  bvn,
  nin,
  accountNumbers,
  isSpecial,
}) => {
  const {
    selectedProduct2,
    amount,
    isLoading,
    productList2,
    handleChange,
    setSpecialPrice,
    resetUserPassword,
    setUserSpecial,
  } = useGlobalContext();
  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    handleChange({ name, value });
  };
  const [showModal, setShowModal] = useState(false);
  function toggleModal() {
    setShowModal(!showModal);
  }
  const [userIsSpecial, setUserIsSpecial] = useState(isSpecial);
  return (
    <div className=" bg-black/50 flex m-auto h-full fixed left-0 right-0 top-0 bottom-0 z-10">
      <div
        className="m-auto bg-white p-4 rounded-md w-[80%] max-w-[400px] text-left"
        key={_id}
      >
        <h4 className="underline text-center">{userName}</h4>
        <div className="font-semibold">
          <p>full Name: {fullName}</p>
          <p>BVN: {bvn || "not provided"}</p>
          <p>NIN: {nin || "not provided"}</p>
          <p>Balance:₦{balance.toFixed(2)}</p>
          <p>phone number: {phoneNumber}</p>
          <p>Account type: {userType}</p>
          <p>email: {email}</p>
          <p>
            last loggedIn:{" "}
            {(lastLogin && moment(lastLogin).startOf("hour").fromNow()) ||
              "unknown"}
          </p>
          <p>Joined on: {moment(createdAt).format("LLL")}</p>
          <p>Referred By: {referredBy}</p>
          <p>Api key: {apiToken}</p>
          {accountNumbers.map((e) => (
            <p>
              {e.bankName}: {e.accountNumber}
            </p>
          ))}

          {isSpecial &&
            specialPrices.length > 0 &&
            specialPrices.map((e, index) => (
              <div key={index} className="flex">
                <p className="mr-4">{e.productName}</p>
                <p>₦{e.price}</p>
              </div>
            ))}
        </div>
        <FormToggle
          toggled={userIsSpecial}
          setToggle={() => setUserIsSpecial(!userIsSpecial)}
          onClick={() => {
            setUserSpecial({ isSpecial: !userIsSpecial, userId: _id });
          }}
        />
        <div className="flex flex-wrap justify-center">
          <button
            className="btn m-1 btn-hipster"
            onClick={() => handleSendEmail(email)}
            key={_id}
          >
            Send Email
          </button>
          <button
            className="btn m-1"
            onClick={() => handleUserTransaction(userName)}
          >
            Transactions
          </button>
          <button
            className="btn m-1"
            onClick={() =>
              handleUpgradeUser({ userId: _id, userType: userType })
            }
          >
            Upgrade user
          </button>
          {showModal && (
            <Modal
              title="set special price"
              buttons={[
                { name: "set price", handleClick: () => setSpecialPrice(_id) },
                {
                  name: "close",
                  className: "btn-danger",
                  handleClick: toggleModal,
                },
              ]}
              children={
                <>
                  <FormRowSelect
                    list={productList2}
                    name="selectedProduct2"
                    labelText="select product"
                    value={selectedProduct2}
                    handleChange={handleInputChange}
                  />
                  <FormInput
                    name="amount"
                    value={amount}
                    type="number"
                    disabled={isLoading}
                    labelText="amount"
                    handleChange={handleInputChange}
                  />
                </>
              }
            />
          )}
          <button onClick={toggleModal} className="btn m-1 btn-danger">
            Add special pricing
          </button>
          <button
            onClick={() => resetUserPassword(_id)}
            className="btn m-1 btn-danger"
          >
            Reset password
          </button>
          <button onClick={close} className="btn m-1 btn-danger">
            close
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
const Wrapper = styled.div`
  z-index: 5;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.5);
  .users {
    @media (min-width: 700px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }

  .card {
    background: var(--grey-100);
    color: var(--primary-500);
    border-radius: var(--borderRadius);
    text-align: center;
    line-height: 1;
    padding: 1rem;
    max-width: 80%;
    margin: 1rem auto;
    font-size: 1rem;
  }
  .info {
    text-align: left;
    p {
      color: var(--primary-500);
      font-weight: 600;
      font-size: 0.7rem;
      text-transform: capitalize;
      line-height: 0.1;
    }
  }
`;
